import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import * as Sentry from "@sentry/react";

// Server (Node/Deno/Bun) initialization (if applicable)
Sentry.init({
  dsn: "https://65ca0a841b7852ad94ddf0d5376e634c@o4507731070746624.ingest.us.sentry.io/4507731077496832",
  _experiments: {
    metricsAggregator: true,
  },
});

// Browser initialization
Sentry.init({
  dsn: "https://65ca0a841b7852ad94ddf0d5376e634c@o4507731070746624.ingest.us.sentry.io/4507731077496832",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.metrics.metricsAggregatorIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Example of incrementing a custom metric
Sentry.metrics.increment("my_metric");

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
